<div class="_wrapper">
  <div class="side_bg">
    <div class="logo_brand d-flex align-items-center">
      <span class="flex_x_center logo-pseudo-md cursor radius_10"></span>
      <h6 class="text-white font_34 weight_600 ml-3 p-2 mb-0">
        Federal University, Kashere
      </h6>
    </div>
    <div class="text">
      <h6 class="text-white fw-800 f-36">Online Examination Portal</h6>
      <p class="text-white">
        <a
          href="https://oayastech.com/"
          target="_blank"
          rel="noopener noreferrer"
          class="text-white text-decoration-none"
          >Powered by Oayastech</a
        >
      </p>
    </div>
  </div>
  <div class="content">
    <div class="logo_brand mobile_view text-center">
      <!-- <img
        src="../../../cbt-admin/assets/img/michika-logo.jpeg"
        alt="School logo"
        class="logo_md cursor radius_10"
      /> -->
      <span class="flex_x_center logo-pseudo-md cursor radius_10"></span>
    </div>
    <div class="form_area">
      <div class="top text-center">
        <h6 class="font-weight-bold font_24 text-app-black">Welcome, Admin</h6>
        <p>Enter your details below to continue to your dashboard</p>
      </div>

      <form [formGroup]="loginForm" (ngSubmit)="join()">
        <div class="">
          <div class="group w-100">
            <label for="firstname" class="fw-600 text-app-black"
              >Username</label
            >
            <input
              type="text"
              id="firstname"
              formControlName="username"
              class="form-control"
              placeholder=""
            />
          </div>
          <div class="group w-100">
            <label for="password" class="fw-600 text-app-black">Password</label>
            <input
              type="password"
              id="firstname"
              class="form-control"
              formControlName="password"
              placeholder=""
            />
          </div>
        </div>
        <button
          class="bg_app_primary text-white btn w-100"
          [disabled]="!loginForm.valid || loader"
        >
          {{ loader ? 'logging in...' : 'Continue' }}
          <i class="fas fa-circle-notch fa-spin ml-4" *ngIf="loader"></i>
        </button>
      </form>
      <footer class="text-center f-14">
        <span>NB: Only verified admin accounts can access this portal</span>
      </footer>
    </div>
  </div>
</div>
