import { catchError, retry, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.url;
  errorHandler(error: HttpErrorResponse): any {
    return throwError(error.message || 'server error.');
  }
  constructor(
    private http: HttpClient
  ) { }


  profile(): Observable<object> {
    const getUrl = this.baseUrl + 'auth';
    return this.http.get(getUrl)
      .pipe(retry(2), tap()
      );
  }

  updateProfile(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'profile/edit';
    return this.http.patch(getUrl, data)
      .pipe(tap()
      );
  }

  login(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'auth';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }

  submit(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'auth';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }

  getQuestions(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'auth';
    return this.http.get(getUrl, data)
      .pipe(tap()
      );
  }
}
