import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginResponse } from 'src/app/Utilities/Models';
import { StaffService } from 'src/app/Utilities/Services/staff/auth.service';
import { UtilService } from 'src/app/Utilities/Services/util.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loader = false;
  phone_valid = 0;
  loginForm: FormGroup;
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private auth: StaffService,
    private util: UtilService,
  ) {
    this.loginForm = this.formbuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  ngOnInit(): void {
  }

  join(): void {
    this.loader = true;
    this.auth.login(this.loginForm.value).subscribe((res: LoginResponse) => {
      this.loader = false;
      this.util.setToken(res.data.token);
      this.util.setUserObject(res.data.admin);
      this.router.navigate(['/']);
      this.util.succesSnackbar('Login successful')
    },
      (err) => {
        this.loader = false;
        this.util.warningSnackbar(err.error.message)
      },
    );
  }

}
