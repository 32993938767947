import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { ExportToCsv } from 'export-to-csv';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  encryptSecretKey = 'diego';
  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private jwtHelper: JwtHelperService,
    private snackBar: MatSnackBar,
  ) {
  }

  private questionsSubject = new BehaviorSubject<any>('');
  questionsData = this.questionsSubject.asObservable();

  private incidentsSubject = new BehaviorSubject<any>('');
  incidentData = this.incidentsSubject.asObservable();

  storeQuestions(data: object): void {
    this.questionsSubject.next(data);
  }

  // AUTH TOKEN
  setUserObject(user: object): void {
    localStorage.setItem('/authuser', JSON.stringify(user));
  }
  getUserObject(): any {
    const user = localStorage.getItem('/authuser');
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }
  setToken(token: string): void {
    localStorage.setItem('/authkey', token);
  }

  getToken(): any {
    return localStorage.getItem('/authkey');
  }

  isAthourized(allowedUsertypes: string[]): any {
    // check if the list of allowedusertpes for aroute is empty,
    // if empty, authorize the user to access the page
    if (allowedUsertypes == null || allowedUsertypes.length === 0) {
      return true;
    }
    const user = this.getUserObject();
    if (user) {
      return allowedUsertypes.includes(user.role);
    }
  }

  getUserType(): string {
    const token: any = localStorage.getItem('/authkey');
    console.log(this.jwtHelper.decodeToken(token));
    return this.jwtHelper.decodeToken(token).scopes;


  }

  isLoggedIn(): boolean {
    const token = localStorage.getItem('/authkey');
    if (!token || this.jwtHelper.isTokenExpired(token)) {
      return false;
    }
    return true;
  }

  logout(): void {
    localStorage.removeItem('/authuser');
    localStorage.removeItem('/authkey');
    this.router.navigateByUrl('/login');
  }

  // determnine time of the day
  dayTime(): string {
    const hr = new Date().getHours();
    if (hr < 12) {
      return 'Good Morning';
    }
    if (hr >= 12 && hr < 16) {
      return 'Good Afternoon';
    }
    if (hr >= 16) {
      return 'Good Evening';
    }
  }

  // SNACKBAR METHODS
  succesSnackbar(msg: string): void {
    this.snackbarConfig(
      'Success', msg, 'success-snackbar'
    );
  }

  errorSnackbar(msg: string): void {
    this.snackbarConfig(
      'Error', msg, 'error-snackbar'
    );
  }

  warningSnackbar(msg: string): void {
    this.snackbarConfig(
      'Warning', msg, 'warning-snackbar'
    );
  }

  snackbarConfig(title: string, msg: string, theme: string): void {
    this.snackBar.open(title, msg, {
      duration: 7000,
      verticalPosition: 'top',
      // horizontalPosition: 'right',
      panelClass: [theme],
    });
  }

  // MATERIAL DIALOG HANDLES
  dialogConfig(): object {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '100%';
    dialogConfig.hasBackdrop = true;
    dialogConfig.closeOnNavigation = true;
    return dialogConfig;
  }

  // SORTING BY NEWSEST
  sortByNewest(data: Array<any>, field: string): Array<any> {
    const sorted = data.sort((a, b) => {
      return new Date(b[field]).getTime() - new Date(a[field]).getTime();
    });
    return sorted;
  }

  nameInitials(text: string): string {
    const split = text.toUpperCase().split(' ');
    const first_letter = split[0].slice(0, 1);
    let second_letter = '';
    split[1] ? second_letter = split[1].slice(0, 1) : second_letter = '';
    return `${first_letter}${second_letter}`;
  }

  dateAgo(time: any): any {
    const today = new Date().getTime();
    const action_date = new Date(time).getTime();
    const diff = (today - action_date) / 1000;
    const mins = Math.floor(diff / 60);
    const hr = Math.floor(diff / 3600);
    const day = Math.floor(diff / (3600 * 24));
    const week = Math.floor(diff / (3600 * 168));
    const month = Math.floor(diff / (3600 * 720));
    const year = Math.floor(diff / (3600 * 8760));
    if (diff < 60) {
      return `${diff} second${diff > 1 ? 's' : ''} ago`;
    }
    if (mins >= 1 && mins < 60) {
      return `${mins} minute${mins > 1 ? 's' : ''} ago`;
    }
    if (hr >= 1 && hr < 24) {
      return `${hr} hour${hr > 1 ? 's' : ''} ago`;
    }
    if (day >= 1 && day < 7) {
      return `${day} day${day > 1 ? 's' : ''} ago`;
    }
    if (day >= 7 && day <= 30) {
      return `${week} week${week > 1 ? 's' : ''} ago`;
    }
    if (day > 30 && day <= 365) {
      return `${day} month${month > 1 ? 's' : ''} ago`;
    }
    if (day > 365) {
      return `${day} year${year > 1 ? 's' : ''} ago`;
    }
  }
  // Method to return list of sessions according to calendar year
  sessionDropdown(mode?: 'upwards' | 'downwards'): Array<string> {
    const options = [];
    for (let index = 0; index < 2; index++) {
      const current = mode && mode === 'upwards' ?
        new Date().getFullYear() + index :
        new Date().getFullYear() - index;
      const prev = current - 1;
      options.push(`${prev}/${current}`);
    }
    return options;
  }
  // CSV EXPORT
  export(data, headers, title): any {
    const options = {
      fieldSeparator: ',',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: `${title}-${Math.floor(Math.random() * 1000) + 1} `,
      useTextFile: false,
      useBom: true,
      headers: []
    };
    options.headers = headers;
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }
}
