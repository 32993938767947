import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StaffForm, StaffGetResponse } from '../../Models/admin';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  clearDbCheck = new BehaviorSubject(false);
  baseUrl = environment.url + 'admin/';
  errorHandler(error: HttpErrorResponse): any {
    return throwError(error.message || 'server error.');
  }
  constructor(
    private http: HttpClient
  ) { }


  createStaff(data: StaffForm): Observable<object> {
    const getUrl = this.baseUrl + 'add';
    return this.http.post(getUrl, data)
      .pipe(retry(1), tap()
      );
  }

  editStaff(data: StaffForm, id: string): Observable<object> {
    const getUrl = this.baseUrl + `edit/${id}`;
    return this.http.post(getUrl, data)
      .pipe(retry(1), tap()
      );
  }

  resetPassword(id: string): Promise<object> {
    const getUrl = this.baseUrl + `reset-password/${id}`;
    return this.http.post(getUrl, {})
      .pipe(retry(1), tap()
      ).toPromise();
  }

  updateProfile(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'profile/edit';
    return this.http.patch(getUrl, data)
      .pipe(tap()
      );
  }

  getStaff(): Observable<StaffGetResponse> {
    const getUrl = this.baseUrl + 'admins';
    return this.http.get<StaffGetResponse>(getUrl)
      .pipe(tap()
      );
  }


  login(data: object): Observable<object> {
    const getUrl = this.baseUrl + 'login';
    return this.http.post(getUrl, data)
      .pipe(tap()
      );
  }

  logout(): Observable<object> {
    const getUrl = this.baseUrl + 'logout-all';
    return this.http.post(getUrl, {})
      .pipe(tap()
      );
  }

  deleteStaff(id: string): Promise<object> {
    const getUrl = this.baseUrl + `delete/${id}`;
    return this.http.delete(getUrl)
      .pipe(retry(1), tap()
      ).toPromise();
  }
  clearDatabase(): Promise<object> {
    const getUrl = this.baseUrl + `clear-db`;
    return this.http.patch(getUrl, {})
      .pipe(retry(1), tap()
      ).toPromise();
  }
}
