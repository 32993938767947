import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Core/login/login.component';
import { NotFoundComponent } from './Core/not-found/not-found.component';
import { UnathourizedComponent } from './Core/unathourized/unathourized.component';
import { HttpinterceptorService } from './Utilities/interceptor/httpinterceptor.service';
import { MaterialModule } from './Utilities/material/material.module';
import { ApiService } from './Utilities/Services/api.service';
import { UtilService } from './Utilities/Services/util.service';

export function tokenGetter(): any {
  return localStorage.getItem('/key');
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    UnathourizedComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {}
    }),

  ],
  providers: [
    UtilService,
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpinterceptorService,
      multi: true
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
